import React, { useEffect, useRef } from 'react';
import { Device, AudioProcessor } from '@twilio/voice-sdk';

let audioContext = new AudioContext();

declare global {
    interface Window {
        onAudioIncoming: (data: Int16Array, sampleRate: number) => void;
        onTextToSpeech: (data: any) => void;
        onTwilioError: (error: string) => void;
    }
}

/*window.onAudioIncoming = (data: Int16Array, sampleRate: number) => {  
    console.log('Audio incoming:', data, sampleRate);    
}*/   

let textToSpeechEnabled = true;
let textToSpeechBytes: any = {};
let isPlaying = false;
let textToSpeechQueue: any = [];
let globalDestination: any;

const onPlayTextToSpeech = (audioBytes: any) => {
    
  if (isPlaying) {
      textToSpeechQueue.push(audioBytes);
      return;
    }
    
    playAudio(audioBytes);

}

const playAudio = (audioBytes: any) => {

  isPlaying = true;

  const buffer = new Float32Array(audioBytes.length / 2);
  for (let i = 0; i < audioBytes.length; i += 2) {
    const value = audioBytes[i] + (audioBytes[i + 1] << 8);
    buffer[i / 2] = value >= 0x8000 ? -(0x10000 - value) / 0x8000 : value / 0x7FFF;
  }

  const audioBuffer = audioContext.createBuffer(1, buffer.length, 16000);
  audioBuffer.copyToChannel(buffer, 0);

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;

  source.connect(globalDestination);
  //source.connect(audioContext.destination);
  source.loop = false;

  source.onended = () => {      
    if (textToSpeechQueue.length > 0) {
      const nextAudioBytes = textToSpeechQueue.shift();
      playAudio(nextAudioBytes);
    } else {
      isPlaying = false;
    }
  };

  source.start();
}

const onAudio = (data: any, language: string, id: string, part: number, maxParts: number) => {
 
  if (!textToSpeechEnabled) {
    return;
  }

  if (!textToSpeechBytes[id]) {
    textToSpeechBytes[id] = {
      chunks: new Array(maxParts),
      chunksAdded: 0,
      created: new Date().getTime()
    };
  }

  textToSpeechBytes[id].chunks[part] = data;
  textToSpeechBytes[id].chunksAdded++;

  if (textToSpeechBytes[id].chunksAdded === maxParts) {

    const totalLength = textToSpeechBytes[id].chunks.reduce((acc: any, int16Array: any) => acc + int16Array.length, 0);
    const playSoundBytes = new Int16Array(totalLength);
    let currentIndex = 0;

    for (const int16Array of textToSpeechBytes[id].chunks) {
      playSoundBytes.set(int16Array, currentIndex);
      currentIndex += int16Array.length;
    }

    if (onPlayTextToSpeech) {
       onPlayTextToSpeech(playSoundBytes);
    }
    delete textToSpeechBytes[id];

  }
}

window.onTextToSpeech = (message: any) => {
  var buffer = message.buffer;
  onAudio(buffer, message.langCode, message.id, message.part, message.maxParts);
};



class BackgroundAudioProcessor implements AudioProcessor {

 async createProcessedStream(stream: MediaStream): Promise<MediaStream> {

   globalDestination = audioContext.createMediaStreamDestination();
   return globalDestination.stream;

 }

 async destroyProcessedStream(stream: MediaStream): Promise<void> {
   globalDestination!.disconnect();
 }
}

const ConferenceListener: React.FC = () => {
  const device = useRef<Device | null>(null);

  const float32ToS16 = (input: any) => {
     const output = new Int16Array(input.length);
     for (let i = 0; i < input.length; i++) {
         const sample = Math.max(-1, Math.min(1, input[i]));
         output[i] = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
     }
     return output.buffer;
  }

  const onError = (error: any) => {
    console.error('Error:', error);
    if (window.onTwilioError) {
      window.onTwilioError(error);
    }
  }

  const sendForProcessing = (stream: MediaStream) => {

    const sampleRate = audioContext.sampleRate;
    const bufferSize = 4096;   

    const processor = audioContext.createScriptProcessor(bufferSize, 1, 1);
    processor!.onaudioprocess = (event: any) => {           
        const input = event.inputBuffer.getChannelData(0);
        const output = event.outputBuffer.getChannelData(0);
        output.set(input);
        const s16Data = float32ToS16(input);          
        if (window.onAudioIncoming) {                
            window.onAudioIncoming(new Int16Array(s16Data), sampleRate);
        }
    };

    const source = audioContext.createMediaStreamSource(stream);
    const destination = audioContext.createMediaStreamDestination();        
    source!.connect(processor);
    processor!.connect(destination);  

  }

  const setupDevice = async () => {
    
    try {

      const searchParams = new URLSearchParams(window.location.search);
      let token = searchParams.get('token');
      const callId = searchParams.get('callId');  
    
      if (!token || !callId) {
          console.error('Token is invalid');
          return;
      }

      device.current = new Device(token);
      
      const processor = new BackgroundAudioProcessor();

      await device.current.audio!.addProcessor(processor);

      const call = await device.current!.connect({
          params: {
              CallId: callId              
          }
      });

      call.on('error', (error) => {
            console.error('Call error:', error);
            onError(error.message);
      });

      call.on('disconnect', () => {
            console.log('Call disconnected');
      });

      call.on('accept', () => {
            console.log('Call accepted');
      });

      call.on('audio', async () => {
        console.log('audio ready');
        const remoteStream = await call.getRemoteStream();

        if (remoteStream) {
          sendForProcessing(remoteStream);
        }
      });

      call.on('ringing', () => {
        console.log('Call ringing');
      });
      
    } catch (error) {
      console.error('Failed to setup Twilio Device:', error);
      onError(error);
    }
  }

  useEffect(() => {

    setupDevice();   

    window.addEventListener('click', () => {
        if (audioContext.state === 'suspended') {
          audioContext.resume();
        }       
      });
  }, []);

  return (
    <div>
      {<p>Connected to the conference in listen-only mode.</p>}
    </div>
  );
};

export default ConferenceListener;